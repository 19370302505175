import React, {FC} from 'react';
import {IconButton, Input, InputGroup, InputLeftElement, InputRightElement} from '@chakra-ui/react';
import {addDays, subDays} from 'date-fns';
import {MdArrowLeft, MdArrowRight} from 'react-icons/md';
import {MysqlDate} from '../utils/format';

interface DatePickerProps {
	value: Date;
	onChange: (date: Date) => void;
}

const DatePicker: FC<DatePickerProps> = ({value, onChange}) => {
	const handlePrevious = () => {
		// use date-fns to go back one day
		const newDate = subDays(value, 1);
		onChange(newDate);
	};

	const handleNext = () => {
		// use date-fns to go forward one day
		const newDate = addDays(value, 1);
		onChange(newDate);
	};

	const dateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const dateValue = event.target.value.split('-');
		const newDate = new Date(`${dateValue[1]}/${dateValue[2]}/${dateValue[0]}`);
		onChange(newDate);
	};

	return (
		<InputGroup size='sm' w='170px'>
			<InputLeftElement>
				<IconButton
					variant='ghost'
					size='sm'
					icon={<MdArrowLeft />}
					aria-label='Previous'
					onClick={handlePrevious}
				/>
			</InputLeftElement>

			<Input type='date' value={MysqlDate(value)} onChange={dateChange} />

			<InputRightElement>
				<IconButton
					variant='ghost'
					size='sm'
					icon={<MdArrowRight />}
					aria-label='Next'
					onClick={handleNext}
				/>
			</InputRightElement>
		</InputGroup>
	);
};

export default DatePicker;
