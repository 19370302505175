import React, {useState} from 'react';
import {Text, TextProps, Box, useBoolean, Collapse} from '@chakra-ui/react';

interface ExpandableTextProps extends TextProps {
	children?: React.ReactNode;
	noOfLines?: number;
}

const ExpandableText: React.FC<ExpandableTextProps> = ({children, noOfLines = 2, ...props}) => {
	const [isHovered, setIsHovered] = useState(false);
	const [isExpanded, {on, off}] = useBoolean(false);

	React.useEffect(() => {
		let timeout: number | undefined;
		if (isHovered) {
			timeout = setTimeout(on, 500);
		} else {
			off();
		}
		return () => clearTimeout(timeout);
	}, [isHovered, on, off]);

	const handleMouseEnter = () => setIsHovered(true);
	const handleMouseLeave = () => setIsHovered(false);

	return (
		<Box
			position='relative'
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			overflow='hidden'
		>
			<Collapse startingHeight={noOfLines * 1.2 * 16} in={isExpanded}>
				<Text {...props}>{children}</Text>
			</Collapse>
			{!isExpanded && !isHovered && (
				<Box
					position='absolute'
					bottom='0'
					left='0'
					right='0'
					height='20px'
					bgGradient='linear(to-t, white, transparent)'
				/>
			)}
		</Box>
	);
};

export default ExpandableText;
