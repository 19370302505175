import React from 'react';
import {Box, Heading, HStack, IconButton, StackDivider, Tag, VStack} from '@chakra-ui/react';
import {useQuery} from '@tanstack/react-query';
import {MdEdit} from 'react-icons/md';
import ExpandableText from '../../../components/ExpandableText';
import SidePanel from '../../../components/SidePanel';
import {Puddleglum} from '../../../puddleglum';
import {OrderDate} from '../../../utils/format';
import AddOrEditChargeForm from './forms/AddOrEditChargeForm';

interface ExistingOrdersProps {
	user: Puddleglum.Models.User;
	bookingRequest: Puddleglum.Models.BookingRequest;
	disabled?: boolean;
}

const ExistingCharges = ({user, bookingRequest, disabled}: ExistingOrdersProps) => {
	const [orderToEdit, setOrderToEdit] = React.useState<Puddleglum.Models.Order>();

	const {data: orders} = useQuery(['recentOrders', user.id], async () => {
		const reply = await Puddleglum.Controllers.Providers.OrdersController.index({
			user_id: user.id,
			booking_request_id: bookingRequest.id,
		});

		return reply.data;
	});

	return (
		<>
			<Box mt={8}>
				<Heading size='sm' color='gray.500'>
					Existing Charges
				</Heading>
				<VStack w='full' divider={<StackDivider />}>
					{orders?.map((order) => (
						<HStack key={order.id} w='full'>
							{order.treatment && (
								<>
									<Box flex={3}>
										<ExpandableText>{order.treatment.treatment}</ExpandableText>
									</Box>
									<Box flex={1}>{order.treatment.treatment_code}</Box>
								</>
							)}
							{order.treatment_group && (
								<Box flex={3}>{order.treatment_group.name}</Box>
							)}
							<Box flex={1}>
								{order.future_time_value > 0 &&
									`${order.future_time_value} ${order.future_time_unit}`}
								{order.estimated_completion_date && (
									<Tag size='sm'>
										Est: {OrderDate(new Date(order.estimated_completion_date))}
									</Tag>
								)}
							</Box>
							<Box flex={1}>{order.status}</Box>
							<Box>
								<IconButton
									aria-label='Edit Order'
									size='sm'
									icon={<MdEdit />}
									onClick={() => setOrderToEdit(order)}
									disabled={disabled}
								/>
							</Box>
						</HStack>
					))}
				</VStack>
			</Box>
			<SidePanel
				isOpen={!!orderToEdit}
				onClose={() => setOrderToEdit(undefined)}
				title='Enter Charge Details'
			>
				<Box w='full' pt={4}>
					<AddOrEditChargeForm
						user={user}
						order={orderToEdit!}
						bookingRequest={bookingRequest}
						onClose={() => setOrderToEdit(undefined)}
						onSave={() => setOrderToEdit(undefined)}
					/>
				</Box>
			</SidePanel>
		</>
	);
};

export default ExistingCharges;
