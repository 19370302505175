import React, {FC} from 'react';
import {CalendarIcon} from '@chakra-ui/icons';
import {forwardRef, Input, InputGroup, InputProps, InputRightElement} from '@chakra-ui/react';
import format from 'date-fns/format';
import ReactDatePicker, {ReactDatePickerProps} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../theme/date-picker.css';

interface DatePickerProps extends ReactDatePickerProps {
	placeholder?: string;
	dateFormat?: string;
	initialValue?: Date;
	inputProps: InputProps;
}

const DatePicker: FC<DatePickerProps> = ({
	placeholder,
	dateFormat = 'yyyy-MM-dd',
	value = '',
	inputProps,
	...props
}) => {
	const date = value ? new Date(value) : new Date();

	const DateInput = forwardRef(({value: inputValue, onClick, onChange}, ref) => (
		<InputGroup onClick={onClick}>
			<Input
				{...inputProps}
				bgColor='white'
				ref={ref}
				placeholder={placeholder ?? ''}
				value={inputValue}
				onChange={onChange}
			/>
			<InputRightElement>
				<CalendarIcon color='gray.500' />
			</InputRightElement>
		</InputGroup>
	));

	return (
		<div className='light-theme'>
			<ReactDatePicker
				{...props}
				selected={date}
				value={format(date, dateFormat)}
				showPopperArrow={false}
				customInput={<DateInput />}
				dateFormat={dateFormat}
			/>
		</div>
	);
};

export default DatePicker;
