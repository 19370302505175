import React from 'react';
import {FormControl, FormErrorMessage, FormLabel} from '@chakra-ui/react';
import {PuddleBag} from '../../hooks/usePuddleForm';
import DateTimePicker from '../DateTimePicker';

interface PuddleDateProps<TModel, TRequest> {
	label: string;
	minDate?: Date;
	maxDate?: Date;
	fieldName: keyof TRequest;
	form: PuddleBag<TModel, TRequest>;
	isRequired?: boolean;
}

const PuddleDateTime = <TModel, TRequest>({
	label,
	fieldName,
	minDate = new Date(2020, 0, 1),
	maxDate = new Date(2100, 0, 1),
	form,
	isRequired = false,
}: PuddleDateProps<TModel, TRequest>) => {
	const value =
		form.model && form.model[fieldName as unknown as keyof TModel]
			? (form.model[fieldName as unknown as keyof TModel] as string)
			: '';

	const handleDateChange = (date: Date) => {
		form.updateField(fieldName, date);
	};

	return (
		<FormControl isInvalid={form.fieldHasError(fieldName)} isRequired={isRequired}>
			<FormLabel color='gray.500'>{label}</FormLabel>
			<DateTimePicker
				showTimeSelect
				dropdownMode='select'
				showMonthDropdown
				showYearDropdown
				minDate={minDate}
				maxDate={maxDate}
				dateFormat='MM/dd/yyyy h:mm aa'
				timeIntervals={15}
				value={value}
				onChange={handleDateChange}
				inputProps={{
					py: 2,
					px: 4,
					bgColor: isRequired && !value ? 'yellow.100' : 'white',
					onBlur: (e) => form.validateField(fieldName, e.target.value),
				}}
			/>
			{form.fieldHasError(fieldName) && (
				<FormErrorMessage fontSize='xs'>{form.getFieldError(fieldName)}</FormErrorMessage>
			)}
		</FormControl>
	);
};
export default PuddleDateTime;
